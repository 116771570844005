import React from 'react';
import Banner2 from '../components/Banner2';
import ChannelsImageTextList from '../components/ChannelsImageTextList';
import Layout from '../components/Layout';
import CasesBanner from '../images/scenes-banner.svg';
import SceneSolutions from '../components/SceneSolutions';
import Pain1 from '../images/pain-1.svg';
import Pain2 from '../images/pain-2.svg';
import Pain3 from '../images/pain-3.svg';
import PainsAndNeeds from '../components/PainsAndNeeds';
import SceneSolutionsImg1 from '../images/scenes-solutions-1.svg';

const Ptitle3 =
  '整合线上线下全渠道营销触点，识别跨平台用户身份，\n' +
  '整合用户行为轨迹，将用户全渠道、全路径数据自动汇总到统一的客户档案';

const VALUE_DATA = [
  {
    id: 'value-1',
    title: '获客渠道',
    main: '分散',
    description: '渠道分散，数据不统一\n无法有效管理，重复及无效数据混杂',
    iconId: Pain1,
  },
  {
    id: 'value-2',
    title: '营销场景',
    main: '割裂',
    description: '营销场景割裂且生硬\n无法有效吸引和打动客户',
    iconId: Pain2,
  },
  {
    id: 'value-3',
    title: '用户画像',
    main: '缺失',
    description: '同个客户分散在不同的营销渠道\n没有做统一整理和合并识别',
    iconId: Pain3,
  },
];

export default () => (
  <Layout current="scenes" subCurrent="channels">
    <Banner2
      title="全渠道获客管理"
      Ptitle3={Ptitle3}
      backgroundImage={CasesBanner}
      showDemoBtn
      buttonText="体验场景DEMO"
    />
    <PainsAndNeeds data={VALUE_DATA} />
    <SceneSolutions src={SceneSolutionsImg1} />
    <ChannelsImageTextList />
  </Layout>
);

import React from 'react';
import ImageText from './ImageText';
import Channels1 from '../images/channels-1.svg';
import Channels2 from '../images/channels-2.svg';
import Channels3 from '../images/channels-3.svg';
import Channels4 from '../images/channels-4.svg';

export default () => {
  return (
    <section id="channels" className="channels-list" style={{ padding: 0 }}>
      <ImageText
        image={Channels1}
        title="公域广告 & 社媒获客"
        description="无需二次开发，一键授权即可对接百度SEM，抖音等平台，留资数据自动导入系统线索池，让跟进更加及时有效。通过多层级的来源架构体系搭建，精确辨别不同维度的来源，以进行来源分层及辨别。SEM批量配置追踪链接，对应单元、计划、关键词一键匹配来源字段，来源信息匹配到客户属性和行为事件属性，清晰洞察客户来源。"
        imageSize={6}
        position="right"
        bgColor="yellow"
        showDemoBtn
      />
      <ImageText
        image={Channels2}
        title="私域社交获客"
        description="提供从在线内容生产到内容流转的一站式服务，统一管理内容分发任务，市场部创建文档、直播、微官网、落地页、H5、视频、邮件、短信、小程序等内容形式，帮助B2B品牌提升品牌认知，促进销售转化；并通过微信 & 企业微信进行一站式集中管理和自动化营销赋能，以任务形式引导SDR/销售发送内容，管理SDR/销售的发送情况及效果。"
        imageSize={6}
        bgColor="white"
        showDemoBtn
      />
      <ImageText
        image={Channels3}
        title="会议营销获客"
        description="线上直播与线下活动是B2B品牌在目标受众中有效提高知名度的重要渠道。Convertlab荟聚的一体化活动管理系统，助力会议主办方实现线上化高效云端管理。从最初的策划构思到活动落地，再到活动后的参会者反馈收集，全面掌握活动（会前/中/后）每个阶段的数据信息，全方位了解每一位参会者，提升管理效率，简化活动运营流程。"
        imageSize={6}
        position="right"
        bgColor="yellow"
        showDemoBtn
      />
      <ImageText
        image={Channels4}
        title="SDR/销售 & 经销商获客"
        description="无论是私域拉新或者活动推广、全员推广/分享裂变都成为了营销者最首选的营销方式，利用SDR/销售/经销商的客户关系网络，让现有客户 & 高意向客户，带来更多潜在客户，传播路径清晰可见，构建品牌私域流量放大器。同时将有兴趣新用户甄别出来，便于销售及时跟进，把握商机。"
        imageSize={6}
        bgColor="white"
        showDemoBtn
      />
    </section>
  );
};
